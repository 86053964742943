import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {


  @Input() userDetails : any;
  constructor(private router : Router) { }

  ngOnInit() {
  }

  logOut(){
    localStorage.clear();
    this.router.navigate(['/']);

  }

}
