import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },

  {
    path: 'masters',
    loadChildren: () => import('../../views/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../views/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../views/reports/reports.module').then(m => m.ReportsModule)
  }, 
  {
    path: 'purchase',
    loadChildren: () => import('../../views/purchase/purchase.module').then(m => m.PurchaseModule)
  }, 
  {
    path: 'sales',
    loadChildren: () => import('../../views/sales/sales.module').then(m => m.SalesModule)
  }, 
  {
    path: 'settings',
    loadChildren: () => import('../../views/settings/settings.module').then(m => m.SettingsModule)
  }, 
  {
    path: 'inventory',
    loadChildren: () => import('../../views/inventory/inventory.module').then(m => m.InventoryModule)
  }, 


  {
    path: 'transactions',
    loadChildren: () => import('../../views/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'production',
    loadChildren: () => import('../../views/production/production.module').then(m => m.ProductionModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('../../views/transfer/transfer.module').then(m => m.TransferModule)
  },
  


];
