import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { HttpService } from '../../services/http.service';
import { apiUrl } from '../../constants/apiUrl-constants';
import {  SharedDataService } from '../../services/shared-data.service';
import { IUserDetails } from '../../models/user.model';
import { IApiResponse } from '../../models/response.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;


  userDetails : IUserDetails;

  constructor(private router: Router, public navServices: NavService,
    private sharedData : SharedDataService,
    private httpService : HttpService,
    public layout: LayoutService) {

      this.httpService.getApi(apiUrl.CheckToken).subscribe((resdata: IApiResponse) => {
        this.userDetails = resdata.result;
        // this.userDetails.isSingleStore = true;
        if(!this.userDetails.enableInventory){
          this.router.navigate(['/']);
          localStorage.clear();
          return;
        }
        if (resdata.success) {
           this.sharedData.userDetails.next(this.userDetails);
          if (this.userDetails) {

          
            if (this.userDetails.roleId == 2 && !this.userDetails.isSingleStore){
              this.menuItems = navServices.COMPANY_MENUITEMS;
            }
            if (this.userDetails.roleId == 3 && this.userDetails.storeTypeId != 6 && !this.userDetails.isSingleStore){
              this.menuItems = navServices.STORE_MENUITEMS;
            }
            if (this.userDetails.roleId == 3 && this.userDetails.storeTypeId == 6 && !this.userDetails.isSingleStore){
              this.menuItems = navServices.WARE_HOUSE_MENUITEMS;
            }

            if(this.userDetails.isSingleStore && this.userDetails.roleId ==  3){
              this.menuItems = navServices.SINGLE_STORE_MENUITEMS;
            }

         



            // this.userDetails.connectInventoryOrder = false;
            if(!this.userDetails.enableStockTransfer){
              this.menuItems = this.menuItems.filter(
                (x) => x.title != "Stock Transfer"
              );
            }


            for (let i = 0; i <= this.menuItems.length; i++) {
              let element = this.menuItems[i];
              if (element != undefined) {
                if (element.children != undefined) {
                  for (let j = element.children.length; j >= 0; j--) {
                    let innerElement = element.children[j];
                    if (innerElement != undefined) {
                      if (!this.userDetails.enableStockTransfer) {
                        if (innerElement.title == "Stock Transfer Report") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Stock Transfer Report By Item") {
                          element.children.splice(j, 1);
                        }
                      }
                      if (!this.userDetails.enableStockReceipt) {
                        if (innerElement.title == "Good Receipt Note") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Good Receipt Note Report") {
                          element.children.splice(j, 1);
                        }
                      }
                      if (!this.userDetails.enablePurchaseRequest) {
                        if (innerElement.title == "Purchase Request") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Purchase Requests Approval") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Quotation Request") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Purchase Request Report By Date") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Quotation Request Report By Date") {
                          element.children.splice(j, 1);
                        }
                      }


                      if (!this.userDetails.enablePurchaseOrder) {
                        if (innerElement.title == "Purchase Order") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Purchase Order Report By Date") {
                          element.children.splice(j, 1);
                        }
                      }

                      if (!this.userDetails.connectInventoryOrder) {
                        if (innerElement.title == "POS Mapping") {
                          element.children.splice(j, 1);
                        }
                      }
                 

                      if (!this.userDetails.enableRecipe) {
                        if (innerElement.title == "Recipe Stock") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Recipe Settlement") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Recipe Transactions Report") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Product Recipes") {
                          element.children.splice(j, 1);
                        }
                        if (innerElement.title == "Recipe Stock Report") {
                          element.children.splice(j, 1);
                        }
                      }
                     
                     
                      // if (!this.userDetails.enablePurchaseOrder) {
                      //   if (innerElement.title == "Purchase Order") {
                      //     element.children.splice(j, 1);
                      //   }
                      //   if (innerElement.title == "Purchase Order Report By Date") {
                      //     element.children.splice(j, 1);
                      //   }
                      // }
                  
                    }
                  }
                }
              }
            }




  
          }

          // this.menuItems = navServices.STAFFMENUITEMS
          
          //   this.navServices.items.next(this.menuItems);
        }
        else {
          localStorage.clear();
          this.router.navigate(['/'])
        }
      })

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  

}
